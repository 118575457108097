export const New = () => {
  return (
    <div className="new">
      <p className="new__title">Какие новости?</p>
      <p className="new__description" style={{ marginBottom: "20px" }}>
        Бонустар мен акциялардың жаңартылған бөлімі
      </p>
      <p className="new__description">
        Картада ең жақын филиалының, банкоматтың немесе терминалдың орналасқан
        жері қосылды.
      </p>
      <p className="new__description">
        Face ID және Touch ID үшін қолдау қосылды.
      </p>
      <p className="new__description">
        Кейбір Android 10 пайдаланушылары кездескен push хабарландыруларына
        қатысты мәселе түзетілді.
      </p>
      <p className="new__description">
        Біз Android пайдаланушылары үшін параметрлердегі мәтіннің өте кішкентай
        болуына әкелетін мәселені шештік.
      </p>
      {/* <p className="new__description">
        • Parametrlərdə mətnin Android istifadəçiləri üçün çox kiçik olması
        problemi həll edildi.
      </p> */}
    </div>
  );
};
